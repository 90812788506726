import { Link, Typography, Box } from '@mui/material';
import React from 'react';

export default function Education(props) {
  return (
    <Box ref={props.education} sx={{ px: '10%', py: '4%', lineHeight: 1.5 }}>
      <Box sx={{ py: '1%' }}>
        <Typography variant='h3' gutterBottom>
          Education 📚
        </Typography>
      </Box>
      <Box sx={{}}>
        <Typography variant='body1'>
          I'm currently doing my master degree on computer science in the Fisics
          and Mathematics Faculty of the University of Chile.
        </Typography>
        <Box
          sx={{ justifyContent: 'center', alignContent: 'center', py: '5%' }}
        >
          <Link href='https://www.dcc.uchile.cl/' target={'_blank'}>
            <img
              src='images/dcc_logo.png'
              alt='dcc_logo'
              style={{ width: '100%' }}
            />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
