import { Typography, Box } from '@mui/material';
import React from 'react';

export default function TechnologiesStack(props) {
  const paddingBetweenImages = '2%';
  const paddingBetweenImageBoxAndTextBox = '5%';
  const paddingImageBox = '1%';
  const imageWidth = '128';
  // List of technologies images where I have more than a year of experience.
  const more_than_one_year_experience = [
    {
      img: 'images/technologies/django.png',
      title: 'django',
    },
    {
      img: 'images/technologies/python.png',
      title: 'python',
    },
    {
      img: 'images/technologies/docker.png',
      title: 'docker',
    },
    { img: 'images/technologies/git.png', title: 'git' },
  ];

  // List of technologies images where I have less than a year of experience.
  const less_than_one_year_experience = [
    {
      img: 'images/technologies/js.png',
      title: 'js',
    },
    { img: 'images/technologies/ts.png', title: 'ts' },
    { img: 'images/technologies/php.png', title: 'php' },
  ];

  // List of technologies images where I have only personal experience.
  const personal_experience = [
    { img: 'images/technologies/java.png', title: 'java' },
    { img: 'images/technologies/c.png', title: 'C' },
    { img: 'images/technologies/aws.png', title: 'AWS' },
    { img: 'images/technologies/react.png', title: 'react' },
  ];

  return (
    <Box
      ref={props.technologiesStack}
      sx={{ px: '10%', py: '4%', lineHeight: 1.5 }}
    >
      <Box sx={{ py: '1%' }}>
        <Typography variant='h3' gutterBottom>
          Technologies Stack 🤓
        </Typography>
      </Box>
      <Box>
        <Typography variant='body1'>
          These are the technologies with which I have had more than a year of
          professional experience.
        </Typography>
        <Box
          sx={{
            justifyContent: 'center',
            alignContent: 'center',
            my: paddingBetweenImageBoxAndTextBox,
            py: paddingImageBox,
            borderWidth: '1px',
            borderColor: 'black',
            borderStyle: 'solid',
          }}
        >
          {more_than_one_year_experience.map((item) => (
            <Box sx={{ px: paddingBetweenImages }} display={'inline'}>
              <img
                src={`${item.img}`}
                alt={`${item.title}`}
                width={`${imageWidth}`}
                loading='lazy'
                key={`${item.title}`}
              />
            </Box>
          ))}
        </Box>
        <Typography variant='body1'>
          These are the technologies with which I have had less than a year of
          professional experience.
        </Typography>
        <Box
          sx={{
            justifyContent: 'center',
            alignContent: 'center',
            my: paddingBetweenImageBoxAndTextBox,
            py: paddingImageBox,
            borderWidth: '1px',
            borderColor: 'black',
            borderStyle: 'solid',
          }}
        >
          {less_than_one_year_experience.map((item) => (
            <Box sx={{ px: paddingBetweenImages }} display={'inline'}>
              <img
                src={`${item.img}`}
                alt={`${item.title}`}
                width={`${imageWidth}`}
                loading='lazy'
                key={`${item.title}`}
              />
            </Box>
          ))}
        </Box>
        <Typography variant='body1'>
          These are the technologies with which I have had personal experience,
          certain technologies had only been use in university courses, others
          in personal proyects.
        </Typography>
        <Box
          sx={{
            justifyContent: 'center',
            alignContent: 'center',
            my: paddingBetweenImageBoxAndTextBox,
            py: paddingImageBox,
            borderWidth: '1px',
            borderColor: 'black',
            borderStyle: 'solid',
          }}
        >
          {personal_experience.map((item) => (
            <Box sx={{ px: paddingBetweenImages }} display={'inline'}>
              <img
                src={`${item.img}`}
                alt={`${item.title}`}
                width={`${imageWidth}`}
                loading='lazy'
                key={`${item.title}`}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
