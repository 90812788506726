import {
  Box,
  ListItem,
  ListItemText,
  Link,
  ListItemButton,
  List,
} from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import React from 'react';

export default function Contact() {
  const buttonColor = '#bdbdbd';
  const buttonBorderRadius = '10px';
  const paddingRightIcon = '5%';
  return (
    <Box
      sx={{
        position: 'fixed',
      }}
    >
      <ListItem>
        <Box
          sx={{
            display: 'flex',
            fontWeight: 700,
            fontSize: 25,
          }}
        >
          Reach Me At:
        </Box>
      </ListItem>
      <List
        sx={{
          fontWeight: 700,
        }}
      >
        <ListItemButton
          sx={{
            ':hover': { backgroundColor: buttonColor },
            borderRadius: buttonBorderRadius,
          }}
        >
          <Link
            underline='none'
            color='inherit'
            href='mailto:jorge.isla@ug.uchile.cl'
            target={'_blank'}
            rel='noopener'
            sx={{ display: 'flex' }}
          >
            <AlternateEmailIcon
              sx={{ paddingRight: paddingRightIcon }}
            ></AlternateEmailIcon>
            <ListItemText
              key={1}
              disableTypography={true}
              primary={'jorge.isla@ug.uchile.cl'}
            ></ListItemText>
          </Link>
        </ListItemButton>

        <ListItemButton
          sx={{
            ':hover': { backgroundColor: buttonColor },
            borderRadius: buttonBorderRadius,
          }}
        >
          <Link
            underline='none'
            color='inherit'
            href='https://github.com/jorgeisla'
            target={'_blank'}
            rel='noopener'
            sx={{
              display: 'flex',
            }}
          >
            <GitHubIcon sx={{ paddingRight: paddingRightIcon }}></GitHubIcon>
            <ListItemText
              key={2}
              disableTypography={true}
              primary={'@jorgeisla'}
            ></ListItemText>
          </Link>
        </ListItemButton>

        <ListItemButton
          sx={{
            ':hover': { backgroundColor: buttonColor },
            borderRadius: buttonBorderRadius,
          }}
        >
          <Link
            underline='none'
            color='inherit'
            href='https://www.linkedin.com/in/jorge-isla-tejo-7218a11bb/'
            target={'_blank'}
            rel='noopener'
            sx={{
              display: 'flex',
            }}
          >
            <LinkedInIcon
              sx={{ paddingRight: paddingRightIcon }}
            ></LinkedInIcon>
            <ListItemText
              key={3}
              disableTypography={true}
              primary={'Jorge_Isla'}
            ></ListItemText>
          </Link>
        </ListItemButton>

        <ListItemButton
          sx={{
            ':hover': { backgroundColor: buttonColor },
            borderRadius: buttonBorderRadius,
          }}
        >
          <Link
            underline='none'
            color='inherit'
            href='https://twitter.com/Comadreja96'
            target={'_blank'}
            rel='noopener'
            sx={{
              display: 'flex',
            }}
          >
            <TwitterIcon sx={{ paddingRight: paddingRightIcon }}></TwitterIcon>
            <ListItemText
              key={4}
              disableTypography={true}
              primary={'@Comadreja96'}
            ></ListItemText>
          </Link>
        </ListItemButton>
      </List>
    </Box>
  );
}
