import {
  Avatar,
  Box,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SchoolIcon from '@mui/icons-material/School';
import WidgetsIcon from '@mui/icons-material/Widgets';
import TerminalIcon from '@mui/icons-material/Terminal';
import { React } from 'react';

export default function Navbar(props) {
  // configs
  const buttonColor = '#bdbdbd';
  const buttonBorderRadius = '10px';
  const dividerWidthPercentage = '80%';
  const py = '3%';
  const paddingRightIcon = '5%';
  // Function to scroll to the selected section
  function scrollToSection(ref) {
    if (ref?.current) {
      ref.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }
  return (
    <Box
      sx={{
        textAlign: 'center',
        position: 'fixed',
      }}
    >
      <ListItemAvatar sx={{ pb: '9%' }}>
        <Avatar
          alt='Jorge Isla'
          src='images/profile-picture.jpg'
          sx={{ width: '140px', height: '140px', m: 'auto' }}
          variant='rounded'
        />
      </ListItemAvatar>

      <Divider
        sx={{
          width: dividerWidthPercentage,
          m: 'auto',
          borderColor: 'black',
          borderWidth: '1px',
        }}
      />

      <ListItemButton
        sx={{
          ':hover': { backgroundColor: buttonColor },
          borderRadius: buttonBorderRadius,
          justifyContent: 'center',
          py: py,
        }}
        onClick={() => scrollToSection(props.introduction)}
      >
        <InsertEmoticonIcon
          sx={{ paddingRight: paddingRightIcon }}
        ></InsertEmoticonIcon>
        <Box
          sx={{
            fontWeight: 700,
          }}
        >
          <ListItemText
            key={'Introduction'}
            primary='Introduction'
            disableTypography={true}
            sx={{
              textAlign: 'center',
            }}
          ></ListItemText>
        </Box>
      </ListItemButton>

      <Divider
        sx={{
          width: dividerWidthPercentage,
          m: 'auto',
          borderColor: 'black',
          borderWidth: '1px',
        }}
      />

      <ListItemButton
        sx={{
          ':hover': { backgroundColor: buttonColor },
          borderRadius: buttonBorderRadius,
          justifyContent: 'center',
          py: py,
        }}
        onClick={() => scrollToSection(props.education)}
      >
        <SchoolIcon sx={{ paddingRight: paddingRightIcon }}></SchoolIcon>
        <Box
          sx={{
            fontWeight: 700,
          }}
        >
          <ListItemText
            key={'Education'}
            primary='Education'
            disableTypography={true}
            sx={{
              textAlign: 'center',
            }}
          ></ListItemText>
        </Box>
      </ListItemButton>

      <Divider
        sx={{
          width: dividerWidthPercentage,
          m: 'auto',
          borderColor: 'black',
          borderWidth: '1px',
        }}
      />

      <ListItemButton
        sx={{
          ':hover': { backgroundColor: buttonColor },
          borderRadius: buttonBorderRadius,
          py: py,
        }}
        onClick={() => scrollToSection(props.technologiesStack)}
      >
        <WidgetsIcon sx={{ paddingRight: paddingRightIcon }}></WidgetsIcon>
        <Box
          sx={{
            fontWeight: 700,
          }}
        >
          <ListItemText
            key={'Technologies Stack'}
            primary='Technologies Stack'
            disableTypography={true}
            sx={{
              textAlign: 'center',
            }}
          ></ListItemText>
        </Box>
      </ListItemButton>

      <Divider
        sx={{
          width: dividerWidthPercentage,
          m: 'auto',
          borderColor: 'black',
          borderWidth: '1px',
        }}
      />

      <ListItemButton
        sx={{
          ':hover': { backgroundColor: buttonColor },
          borderRadius: buttonBorderRadius,
          py: py,
        }}
        onClick={() => scrollToSection(props.professionalExperience)}
      >
        <TerminalIcon sx={{ paddingRight: paddingRightIcon }}></TerminalIcon>
        <Box
          sx={{
            fontWeight: 700,
          }}
        >
          <ListItemText
            key={'Professional Experience'}
            primary='Professional Experience'
            disableTypography={true}
            sx={{
              textAlign: 'center',
              width: '80%',
            }}
          ></ListItemText>
        </Box>
      </ListItemButton>

      <Divider
        sx={{
          width: dividerWidthPercentage,
          m: 'auto',
          borderColor: 'black',
          borderWidth: '1px',
        }}
      />

      <ListItemButton
        sx={{
          ':hover': { backgroundColor: buttonColor },
          borderRadius: buttonBorderRadius,
          justifyContent: 'center',
          py: py,
        }}
        onClick={() => scrollToSection(props.hobbies)}
      >
        <SportsEsportsIcon
          sx={{ paddingRight: paddingRightIcon }}
        ></SportsEsportsIcon>
        <Box
          sx={{
            fontWeight: 700,
          }}
        >
          <ListItemText
            key={'Hobbies'}
            primary='Hobbies'
            disableTypography={true}
            sx={{
              textAlign: 'center',
            }}
          ></ListItemText>
        </Box>
      </ListItemButton>

      <Divider
        sx={{
          width: dividerWidthPercentage,
          m: 'auto',
          borderColor: 'black',
          borderWidth: '1px',
        }}
      />
    </Box>
  );
}
