import { Box, Link, Typography } from '@mui/material';

import React from 'react';

export default function Hobbies(props) {
  return (
    <Box ref={props.hobbies} sx={{ px: '10%', py: '4%', lineHeight: 1.5 }}>
      <Box sx={{ py: '1%' }}>
        <Typography variant='h3' gutterBottom>
          Hobbies 🏋️ 🎲 🎮
        </Typography>
      </Box>
      <Box>
        <Box sx={{ py: '1%' }}>
          <Typography variant='body1' sx={{ display: 'inline' }}>
            Aside from programmig I enjoy a lot of things. I'm actually really
            in to board games. You can check my BGG list
          </Typography>
          <Link
            sx={{ display: 'inline' }}
            underline='hover'
            href='https://boardgamegeek.com/collection/user/comadreja96?own=1&subtype=boardgame&ff=1'
          >
            {' here.'}
          </Link>
        </Box>
        <Box sx={{ py: '1%' }}>
          <Typography sx={{ display: 'inline' }}>
            I play videogames too. I like to read books and spend time with my
            family. I'm trying to improve my cooking skill, so I dont know if
            that is a hobby but I like to try new recipes every time that I can.
            I'm not that much into movies and series but I like to watch it with
            friends and family. In sports, I like to go to the gym, ride the
            bike and walk.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
