import { Box, Link, Typography } from '@mui/material';
import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

export default function ProfessionalExperience(props) {
  return (
    <Box
      ref={props.professionalExperience}
      sx={{ px: '10%', py: '4%', lineHeight: 1.5 }}
    >
      <Box sx={{ py: '1%' }}>
        <Typography variant='h3' gutterBottom>
          Professional Experience 🧑‍💻
        </Typography>
      </Box>
      <Box>
        <Typography variant='body1'>
          I've been working mainly as a freelancer, however, this year I started
          to work under contract in
          <Link
            href='https://www.kwiyx.com/'
            underline='none'
            color='inherit'
            target={'_blank'}
            rel='noopener'
          >
            {' Kwiyx 🥝. '}
          </Link>
        </Typography>
        <Typography variant='body1'>
          Below I will show you my professional experience timeline.
        </Typography>
        <Timeline>
          <TimelineItem>
            <TimelineOppositeContent color='text.secondary'>
              2022{' '}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color='success' />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {' '}
              <Link
                href='https://www.kwiyx.com/'
                underline='none'
                color='inherit'
                target={'_blank'}
                rel='noopener'
              >
                {'Kwiyx 🥝'}
              </Link>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent color='text.secondary'>
              2021
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color='success' />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {' '}
              <Link
                href='https://www.via56.cl/'
                underline='none'
                color='inherit'
                target={'_blank'}
                rel='noopener'
              >
                {'Via56'}
              </Link>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent color='text.secondary'>
              2020
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color='warning' />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>Unemployed</TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent color='text.secondary'>
              2019
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color='success' />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>FUNXPERIENCE</TimelineContent>
          </TimelineItem>
        </Timeline>
        <Typography>
          {
            'If you want to know more about what I have done this years, you can download my '
          }
          <Link
            href='https://jislawebpagebucket.s3.amazonaws.com/resumeJorgeIsla.pdf'
            target={'_blank'}
            rel='noopener'
          >
            {'RESUME.'}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
