import { Box, Typography } from '@mui/material';

import React from 'react';

export default function Introduction(props) {
  return (
    <Box ref={props.introduction} sx={{ px: '10%', py: '4%', lineHeight: 1.5 }}>
      <Box sx={{ py: '1%' }}>
        <Typography variant='h3' gutterBottom>
          Hi everybody !! 👋
        </Typography>
      </Box>
      <Box sx={{ py: '1%' }}>
        <Typography variant='h5'>My name is Jorge Isla.</Typography>
      </Box>
      <Box sx={{ py: '1%' }}>
        <Typography variant='body1'>
          I'm a computer science student and software developer from Chile, and
          this is my website and my way to introduce myself to the world.
        </Typography>
      </Box>
    </Box>
  );
}
