import { Box, Link, Typography } from '@mui/material';
import React from 'react';

export default function Contact(props) {
  return (
    <Box ref={props.hobbies} sx={{ px: '10%', py: '4%', lineHeight: 1.5 }}>
      <Box sx={{ py: '1%' }}>
        <Typography variant='h3' gutterBottom>
          Contact ☎️ 📫
        </Typography>
      </Box>
      <Box>
        <Box sx={{ py: '1%' }}>
          <Typography variant='body1'>
            You can reach me at my personal mail{' '}
            <Link
              color='inherit'
              href='mailto:jorge.isla@ug.uchile.cl'
              target={'_blank'}
              rel='noopener'
            >
              jorge.isla@ug.uchile.cl
            </Link>
            {'. '}
            Also you can reach me at my twitter{' '}
            <Link
              color='inherit'
              href='https://twitter.com/Comadreja96'
              target={'_blank'}
              rel='noopener'
            >
              @Comadreja96
            </Link>{' '}
            or linkedin{' '}
            <Link
              color='inherit'
              href='https://www.linkedin.com/in/jorge-isla-tejo-7218a11bb/'
              target={'_blank'}
              rel='noopener'
            >
              JorgeIsla
            </Link>
            .
          </Typography>
        </Box>
        <Box sx={{ py: '1%' }}>
          <Typography variant='body1'>
            Here is my github{' '}
            <Link
              color='inherit'
              href='https://github.com/jorgeisla'
              target={'_blank'}
              rel='noopener'
            >
              @jorgeisla
            </Link>{' '}
            where you can see some of my projects.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
