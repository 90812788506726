import { Divider, Grid, Box } from '@mui/material';

import React from 'react';

import Introduction from './components/content/about_me';
import ContactBar from './components/contact_bar';
import Hobbies from './components/content/hobbies';
import Navbar from './components/navbar';
import Education from './components/content/education';
import TechnologiesStack from './components/content/technologies_stack';
import PageTitle from './components/page_title';
import ProfessionalExperience from './components/content/professsional_experience';
import Contact from './components/content/contact';

export default function App() {
  const introduction = React.createRef();
  const hobbies = React.createRef();
  const education = React.createRef();
  const technologiesStack = React.createRef();
  const professionalExperience = React.createRef();

  return (
    <Box sx={{ position: 'relative', px: '7%' }}>
      <Grid container spacing={0}>
        <Grid
          item
          md={3}
          sx={{
            pt: '5%',
            display: {
              xs: 'none',
              sm: 'none',
              md: 'flex',
            },
            justifyContent: 'center',
            borderStyle: 'hidden ridge',
            borderWidth: '3px',
          }}
        >
          <Navbar
            introduction={introduction}
            hobbies={hobbies}
            education={education}
            technologiesStack={technologiesStack}
            professionalExperience={professionalExperience}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          sx={{
            pt: '4%',
          }}
        >
          <PageTitle />
          <Divider></Divider>
          <Introduction introduction={introduction} />
          <Divider></Divider>
          <Education education={education} />
          <Divider></Divider>
          <TechnologiesStack technologiesStack={technologiesStack} />
          <Divider></Divider>
          <ProfessionalExperience
            professionalExperience={professionalExperience}
          />
          <Divider></Divider>
          <Hobbies hobbies={hobbies} />
          <Divider></Divider>
          <Grid
            sx={{
              display: {
                xs: 'flex',
                sm: 'none',
                md: 'none',
              },
            }}
          >
            <Contact />
          </Grid>
        </Grid>
        <Grid
          item
          xs={0}
          sm={4}
          md={3}
          sx={{
            pt: '5%',
            justifyContent: 'center',
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'flex',
            },
            borderWidth: '3px',
            borderStyle: 'hidden ridge hidden ridge',
          }}
        >
          <ContactBar />
        </Grid>
      </Grid>
    </Box>
  );
}
